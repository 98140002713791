.cell {
    margin: 0;
    border: 2px solid;
    border-color: #eeeeee #999999 #999999 #eeeeee;
    background-color: #cccccc;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    box-sizing: border-box; /* avoids jumpiness when zoomed */
    width: 24px;
    max-width: 24px; /* avoids being resized if text is too long (iOS) */
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    cursor: pointer;
}

.isMined {
    background-color: #bbbbbb;
    border-width: 1px;
    border-color: #999999;
    padding: 1px;
    cursor: default;
}

.cell1 {
    color: #3333cc;
}
.cell2 {
    color: #006600;
}
.cell3 {
    color: #cc0000;
}
.cell4 {
    color: #660066;
}
.cell5 {
    color: #006666;
}
.isExploded {
    color: #ff0000;
}
