.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px
}

.app > div {
  margin-top: 10px;
}
